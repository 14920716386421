.prefix-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 50%;
  translate: -50% -50%;

  opacity: 0;
  position: absolute;
  top: 45%;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #4a9ce8;
  border-color: #4a9ce8;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.ant-tooltip-inner {
  color: red !important;
}

.custom-range-picker {
  max-width: 250px !important;
  max-height: 48px !important;
  padding: 10px 0;
  border-radius: 30px !important;
  overflow: hidden !important;
  background-color: #f4f4f4 !important;
  .ant-picker-input {
    input {
      font-size: 10px !important;
      @media screen and (min-width:1536px) {
        font-size: 14px !important;
      }
    }
  }
}

.custom-range-picker-borderd{
  max-width: 250px !important;
 height: 48px !important;
  padding: 10px 0;
  border: solid 1px #E0E0E0 !important;
  border-radius: 30px !important;
  overflow: hidden !important;
  .ant-picker-input {
    input {
      font-size: 10px !important;
      @media screen and (min-width:1536px) {
        font-size: 14px !important;
      }
    }
  }
}

.chart-date {
  border: none !important;
  .ant-select-selector {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.borderless-select {
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .ant-select-arrow{
    inset-inline-end:30px !important;
  }
  .ant-select-selection-item {
    font-size: 14px;
    font-weight: 800;
    padding-right: 0px !important;
    @media screen and (min-width:1536px) {
      font-size: 16px !important;
    }
  }
  .ant-select-selection-placeholder{
    font-size: 14px !important;
    color: black !important;
    opacity: 70% !important;
    @media screen and (min-width:1536px) {
      font-size: 16px !important;
    }
  }
}

.borderled-input {
  background-color: transparent !important;
}

.borderd-select {
  .ant-select-arrow{
    inset-inline-end:10px !important;
  }
  .ant-select-selector {
    height: 48px !important;
  }
  .ant-select-selection-search{
    display: flex !important;
    align-items: center !important;
  }
  .ant-select-selection-placeholder{
    display: flex !important;
    align-items: center !important;
    font-size: 14px !important;
    color: rgb(176, 176, 176);
    padding: 5px !important;
  }
  .ant-select-selection-item{
    display: flex;
    align-items: center;
  }
}
.datepicker{
  input{
    font-size: 18px !important;
  }
}