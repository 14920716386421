.apexcharts-bar-area:hover {
  fill: #121212;
}

.apexcharts-bar-area {
  opacity: 1;
}
.bar-chart{
    .bar-tooltip {
        padding: 15px !important;
        width: 200px;
        height: 100px;
        position: relative;
        
       p:first-child{
          font-size: 28px;
       }
      }
      
      
       .apexcharts-tooltip {
        transform: translateX(-50%) translateY(-80px) !important;
        border-radius: 20px !important;
        box-shadow: none !important;
      }
}


.section-card{
    &::-webkit-scrollbar{
        display: none;
    }
}