.proffession{
    width: 100% !important;
    background-color: #F4F4F4 !important;
        border-radius: 30px !important;
        height: 3.3rem !important;
     .ant-select-selector{
        border: none !important;
        background-color: #F4F4F4 !important;
        border-radius: 30px !important;
        padding: .8rem 1.5rem !important;
        height: 100% !important;
     }
     .ant-select-selection-placeholder{
        font-size: 16px !important;
        font-weight: 600 !important;
        
     }
    .ant-select-selector {
      padding-left: calc(3.5rem - 8px) !important;
    }
}