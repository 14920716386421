.dropdown-scroll {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e2e2e2;
  }
}
.modal-head {
  .ant-modal-close {
    top: 45px !important;
    right: 25px !important;
  }
  // .ant-modal-settings {
  //   top: 25px !important;
  // }
  .ant-modal-title {
    font-size: 23px !important;
    margin-bottom: 1.5rem;
  }
  .ant-modal-content {
    border-radius: 30px !important;
    padding: 30px !important;
  }
}

.issue-date {
  width: 9rem !important;
  input {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
 
}
.ant-picker-dropdown{
  z-index: 9999 !important;

}
.slider{
  font-size: 16px; 
}